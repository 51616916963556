import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [];

export const dictionary = {
		"/": [~4],
		"/article/[eventSlug]": [~6],
		"/checkout": [7,[2]],
		"/checkout/confirm-appointment": [8,[2]],
		"/checkout/confirm-email": [9,[2]],
		"/checkout/event-occupied": [10,[2]],
		"/checkout/form": [11,[2]],
		"/checkout/success": [12,[2]],
		"/checkout/summary": [13,[2]],
		"/checkout/ticket-selection": [14,[2]],
		"/kundenfeedback": [15,[3]],
		"/kundenfeedback/steps": [~16,[3]],
		"/kundenfeedback/thank-you": [~17,[3]],
		"/profile": [18],
		"/rechtstext/kickerturnier": [19],
		"/rechtstext/konzertkarten": [20],
		"/rechtstext/samsungfanwochen": [21],
		"/rechtstext/tippspiel": [22],
		"/rechtstext/vrkickerturnier": [23],
		"/[eventSlug]": [~5]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';