import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
	Sentry.init({
		enabled: false
		// dsn: 'https://e97383f7495e45d0a71f916d3b6c2079@sentry.dev.i22.de/126',
		// tracesSampleRate: 1.0,

		// // This sets the sample rate to be 10%. You may want this to be 100% while
		// // in development and sample at a lower rate in production
		// replaysSessionSampleRate: 0.1,

		// // If the entire session is not sampled, use the below sample rate to sample
		// // sessions when an error occurs.
		// replaysOnErrorSampleRate: 1.0,

		// // If you don't want to use Session Replay, just remove the line below:
		// integrations: [replayIntegration()],
	});
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
